// components
import WideCard from '../../components/WideCard/WideCard';
import LongCard from '../../components/LongCard/LongCard';

const Experience = () => {
  return (
    <div className='row'>
      <div className='col-md-8'>
        <WideCard
          title='Software Developer'
          company='Scotiabank'
          duration='2023 - Present'
        >
          <li className='arrow'>Create Web Application for Customer flexibility.</li>
          <li className='arrow'>Create re-usable React Components and Pages</li>
          <li className='arrow'>Created contents to be Accessible</li>
          <li className='arrow'>Perform code reviews for peer developers</li>
          <li className='arrow'>Optimize the existing components</li>
          <li className='arrow'>Worked with HTML5/CSS3/SASS/JS technologies.</li>
          <li className='arrow'>Worked in an Agile environment</li>
          <li className='arrow'>Used Git version control with Jira</li>
        </WideCard>
        <WideCard
          title='Front-end Web developer'
          company='Freelancer'
          duration='2019 - 2022'
        >
          <li className='arrow'>
            Developed React websites using ES6 and various building tools
          </li>
          <li className='arrow'>
            Used Git version control with Jira application
          </li>
          <li className='arrow'>
            Responsive user interface design and development
          </li>
          <li className='arrow'>
            Used Bootstrap5 and Material Ui for Customizability
          </li>
          <li className='arrow'>Created Reusable React Components and Pages</li>
          <li className='arrow'>
            Developed new features based on the functional specifications
          </li>
          <li className='arrow'>
            Used JSX/HTML5/CSS3, Git, and SASS for CSS pre-processing for
            various projects
          </li>
          <li className='arrow'>
            Consistently met dedlines and requirments for all work orders
          </li>
        </WideCard>
      </div>
      <div className='col-md-4'>
        <LongCard />
      </div>
    </div>
  );
};

export default Experience;
