import { Avatar } from '@mui/material';

// assets
import avatarImg from './img/avatar.jpg';

const ProfilePic = () => {
  return (
    <Avatar
      alt='Remy Sharp'
      src={avatarImg}
      sx={{
        width: { lg: 180, xs: 130 },
        height: { lg: 180, xs: 130 },
        marginTop: '-85px',
        marginLeft: '15px',
      }}
    />
  );
};

export default ProfilePic;
