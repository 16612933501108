import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tab, Box } from '@mui/material';

// Components

// styles
import './style.css';

const Tabs = () => {
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <NavLink
          to={'/'}
          style={{
            textDecoration: 'none',
            color: 'inherit',
          }}
          className={({ isActive, isPending }) =>
            isActive ? 'active' : isPending ? 'pending' : ''
          }
        >
          <Tab label='About' />
        </NavLink>
        <NavLink
          to={'education'}
          style={{ textDecoration: 'none', color: 'inherit' }}
          className={({ isActive, isPending }) =>
            isActive ? 'active' : isPending ? 'pending' : ''
          }
        >
          <Tab label='Education' />
        </NavLink>
        <NavLink
          to={'work'}
          style={{ textDecoration: 'none', color: 'inherit' }}
          className={({ isActive, isPending }) =>
            isActive ? 'active' : isPending ? 'pending' : ''
          }
        >
          <Tab label='Work Experience' />
        </NavLink>
      </Box>
    </>
  );
};

export default Tabs;
