// styles
import './Heading.scss';

const Heading = (props) => {
  return (
    <div className='heading position-relative d-flex text-center mb-5'>
      <h2 className='bg-text text-uppercase fw-600 w-100 mb-0'>
        {props.bgTitle}
      </h2>
      <p className='ft-text position-absolute w-100 align-self-center lh-base mb-0'>
        {props.ftTitle}
        <span className='ft-border border-primary border-bottom border-3 d-block mx-auto'></span>
      </p>
    </div>
  );
};

export default Heading;
