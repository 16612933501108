import { Box } from '@mui/material';

// Assets
import coverImg from './img/cover.jpg';

const Cover = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: { lg: 250, xs: 150 },
        backgroundImage: `url(${coverImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  );
};

export default Cover;
