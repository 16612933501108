import * as React from 'react';
import { Box, Button, Modal } from '@mui/material';

// styles
import './TimelineCard.scss';

const style = {
  // width: { sm: 'fit-content', lg: '1250px' },
  display: 'block',
  width: 'fit-content',
  height: 'auto',
  position: 'relative',
  overflow: 'hidden',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  m: 'auto',
};

const imgStyle = {
  display: 'block',
  maxWidth: '100%',
};

const TimelineCard = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className='TimelineCard' style={{ backgroundColor: props.bgColor }}>
      <span className='company'>{props.company}</span>
      <h3 className='title'>{props.title}</h3>
      <Button className='badge bg-primary text-light' onClick={handleOpen}>
        View Certificate
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <img
            src={props.imgSrc}
            className='img'
            style={imgStyle}
            alt='certificate'
          />
        </Box>
      </Modal>
    </div>
  );
};

export default TimelineCard;
