import React from 'react';

// style
import './ProgressLine.scss';

const ProgressLine = (props) => {
  return (
    <>
      <div className='skill'>
        <h4>{props.title}</h4>
        <div className='skill-value'>{props.value}%</div>
      </div>
      <div className='skill-container'>
        <div
          className='skill-percentage'
          style={{ width: `${props.percent}%` }}
        ></div>
      </div>
    </>
  );
};

export default ProgressLine;
