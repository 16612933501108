import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
// styles
import './Form.scss';

const Form = () => {
  const form = useRef();
  const formSubmitControl = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'default_service',
        'template_9wh69n4',
        form.current,
        '-3B_iYMGOQL2WkHwB'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className='row d-flex justify-content-center form'>
      <div className='col-sm-10 col-md-6'>
        <form className='form-box' ref={form} onSubmit={formSubmitControl}>
          <div className='mb-3'>
            <label htmlFor='name' className='form-label'>
              Name
            </label>
            <input
              type='text'
              name='name'
              className='form-control'
              aria-label='Name'
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='email' className='form-label'>
              Email
            </label>
            <input
              type='email'
              name='email'
              className='form-control'
              id='inputEmail4'
              aria-describedby='emailHelp'
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='subject' className='form-label'>
              Subject
            </label>
            <input
              type='text'
              namee='subject'
              className='form-control'
              aria-label='Subject'
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='message' className='form-label'>
              Message
            </label>
            <textarea
              className='form-control'
              name='message'
              id='exampleFormControlTextarea1'
              rows='3'
            ></textarea>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='submit'
              className='btn btn-primary btn-style'
              value='Send'
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
