// components
import Heading from './../../components/Heading/Heading';
import ProgressLine from '../../components/ProgressLine/ProgressLine';
import WorkCard from '../../components/WorkCard/WorkCard';
import OverlayModel from '../../components/OverlayModel/OverlayModel';
import Form from '../../components/Form/Form';

// styles
import './About.scss';

// assets
import AlphaEmpire from '../../assets/alpha-empire.png';
import YogaImme from '../../assets/yoga-imme.png';
import MyItem from '../../assets/myitem.png';
import BankLife from '../../assets/banklife.png';
import ListExpense from '../../assets/list-expense.png';

import Resume from '../../assets/Thineshraj-Resume.pdf';

const About = () => {
  return (
    <div className='about'>
      <Heading bgTitle='about me' ftTitle='Know Me More' />
      <div className='row intro'>
        <div className='col-lg-8 left'>
          <h2 className='title'>
            I'm
            <span> Thineshraj,</span> a Software Developer
          </h2>
          <p className='desc'>
            Innovative Web Application Developer with 3 years’ experience building and
            maintaining responsive websites and applications. Proficient in
            HTML, CSS, JavaScript: plus, modern libraries and frameworks.
          </p>
          <div className='resume'>
            <a href={Resume} download='Thineshraj-Resume.pdf'>
              <button className='btn btn-primary px-4'>Resume</button>
            </a>
          </div>
        </div>
        <div className='col-lg-4 right'>
          <ul className='details-lists'>
            <li className='list'>
              <span className='me-2'>Name:</span>Thineshraj Soosaiselvarajah
            </li>
            <li className='list'>
              <span className='me-2'>Email:</span>
              <a href='mailto:thinesh7474@gmail.com'>thinesh7474@gmail.com</a>
            </li>
            <li className='list'>
              <span className='me-2'>Age:</span>24
            </li>
            <li className='list'>
              <span className='me-2'>From:</span>Toronto, ON Canada
            </li>
            <li className='list social-container'>
              <span className='me-2'>Social:</span>
              <div className='social-icons'>
                <a
                  href='https://www.linkedin.com/in/thineshraj-soosaiselvarajah-984590162'
                  target='_blank'
                >
                  <i className='bi bi-linkedin'></i>
                </a>
                <a
                  href='https://stackoverflow.com/users/20028600/thineshraj-s'
                  target='_blank'
                >
                  <i className='bi bi-stack-overflow'></i>
                </a>
                <a href='mailto:thinesh7474@gmail.com' target='_blank'>
                  <i className='bi bi-envelope-fill'></i>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* Skills */}
      <div className='skills'>
        <Heading bgTitle='about me' ftTitle='Skills' />
        <div className='row skills-row'>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='React' value='85' percent='85' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='Redux' value='70' percent='70' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='ES6' value='90' percent='90' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='HTML' value='98' percent='98' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='CSS' value='95' percent='95' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='Native JavaScript' value='90' percent='90' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='Laravel' value='70' percent='70' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='Node/NPM' value='70' percent='70' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='jQuery' value='75' percent='75' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='SASS' value='90' percent='90' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='Bootstrap 5' value='95' percent='95' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='Responsive Design' value='90' percent='90' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='Git' value='85' percent='85' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='MySql' value='65' percent='65' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='PHP' value='70' percent='70' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='Postman' value='95' percent='95' />
          </div>
          <div className='col-sm-6 col-lg-4'>
            <ProgressLine title='Docker' value='65' percent='65' />
          </div>
        </div>
      </div>
      {/* My Work */}
      <div className='projects'>
        <Heading bgTitle='Portfolio' ftTitle='My Works' />
        <div className='row'>
          <div className='col-12 col-sm-6 col-md-4 '>
            <WorkCard
              targetOverlayId='#exampleModal2'
              bgImg={YogaImme}
              title='Yoga Immi'
              subTitle='Yoga Tech'
            />
            <OverlayModel
              overlayId='exampleModal2'
              title='Yoga Immigration'
              imgSrc={YogaImme}
              modelInfo='It is a CRM application. Providing services for International Student applicants to Canada.'
              detailsName='Yoga Technologies'
              tags={[
                'React',
                'Laravel',
                'MySql',
                'SASS',
                'MUI',
                'Bootstrap',
                'Docker',
              ]}
              detailsDate='2022-09-18'
              detailsUrl='www.yogaimmigration.com'
              detailsUrlName='Progress...'
            />
          </div>
          <div className='col-12 col-sm-6 col-md-4 '>
            <WorkCard
              targetOverlayId='#exampleModal3'
              bgImg={MyItem}
              title='My Item'
              subTitle='Yoga Tech'
            />
            <OverlayModel
              overlayId='exampleModal3'
              title='My Item'
              imgSrc={MyItem}
              modelInfo='MyItem is an attractive HTML Bootstrap 5.x template specially designed for the multipurpose shops like mega store, grocery store, supermarket, organic shop, and online stores selling products like beverages, vegetables, fruits, ice creams, paste, herbs, juice, meat, cold drinks, sausages, cocktails, soft drinks, cookies…'
              detailsName='Yoga Technologies'
              tags={['HTML', 'CSS', 'JavaScript', 'Bootstrap']}
              detailsDate='2021-11'
              detailsUrl='myitem.netlify.app/'
              detailsUrlName='My Item'
            />
          </div>
          <div className='col-12 col-sm-6 col-md-4 '>
            <WorkCard
              targetOverlayId='#exampleModal4'
              bgImg={BankLife}
              title='BankLife'
              subTitle='Yoga Tech'
            />
            <OverlayModel
              overlayId='exampleModal4'
              title='BankLife'
              imgSrc={BankLife}
              modelInfo='A simpler banking experience for a simpler life.'
              detailsName='Yoga Technologies'
              tags={['HTML', 'CSS', 'JavaScript', 'Bootstrap']}
              detailsDate='2021-04'
              detailsUrl='banklife-thinesh-sample.netlify.app/'
              detailsUrlName='BankLife'
            />
          </div>
          <div className='col-12 col-sm-6 col-md-4 '>
            <WorkCard
              targetOverlayId='#exampleModal'
              bgImg={AlphaEmpire}
              title='Alpha Empire'
              subTitle='StyloTek'
            />
            <OverlayModel
              overlayId='exampleModal'
              title='Alpha Empire'
              imgSrc={AlphaEmpire}
              modelInfo='It has a stylish UI and lots of features related to this particular industry, including: property listings (grid & list view), advanced property filter and search, properties map (built using Google maps API), Agents profile page (with submit new property & properties Details), different styles of individual property pages and much more.'
              detailsName='StyloTek'
              tags={['HTML', 'CSS', 'JS']}
              detailsDate='2021-09'
              detailsUrl='/'
              detailsUrlName='Not finished yet'
            />
          </div>
          <div className='col-12 col-sm-6 col-md-4 '>
            <WorkCard
              targetOverlayId='#exampleModal5'
              bgImg={ListExpense}
              title='List Expense'
              subTitle='Yoga Tech'
            />
            <OverlayModel
              overlayId='exampleModal5'
              title='List Expense'
              imgSrc={ListExpense}
              modelInfo='Basic Expense filter application by using React Redux toolkit'
              detailsName='Yoga Technologies'
              tags={['React', 'Redux', 'SASS', 'Firebase']}
              detailsDate='2019-08'
              detailsUrl='list-expense.netlify.app/'
              detailsUrlName='List Expense'
            />
          </div>
        </div>
      </div>

      {/* Contact */}
      <div className='contact'>
        <Heading bgTitle='Form' ftTitle='Contact' />
        <Form />
      </div>
    </div>
  );
};

export default About;
