// styles
import './LongCard.scss';

const LongCard = () => {
  const datas = [
    'HTML',
    'CSS',
    'JavaScript',
    'React',
    'Laravel',
    'NodeJs',
    'jQuery',
    'SASS',
    'Bootstrap',
    'Material UI',
    'GitHub',
    'BitBucket',
    'Jira',
    'Postman',
    'Docker',
  ];

  return (
    <div className='longCard'>
      <h4 className='text-center longCard--title'>
        Knowledge
        <span className='ft-border border-bottom border-3 d-block mx-auto mt-2'></span>
      </h4>
      <div className='contect'>
        <ul>
          {datas.map((data, index) => (
            <li className='badge rounded-pill bg-secondary' key={index}>
              {data}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LongCard;
