import React from 'react';

// styles
import './WorkCard.scss';

const WorkCard = (props) => {
  return (
    <>
      <figure className='workCard'>
        <img src={props.bgImg} />
        <div className='overlay'></div>
        <figcaption className='card-caption'>
          <div className='card-caption-inner'>
            <h3 className='card-title'>{props.title}</h3>
            <h4 className='card-cat'>{props.subTitle}</h4>
          </div>
        </figcaption>
        <a
          href='#'
          type='button'
          data-bs-toggle='modal'
          data-bs-target={props.targetOverlayId}
        ></a>
      </figure>
    </>
  );
};

export default WorkCard;
