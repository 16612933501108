// styles
import './OverlayModel.scss';

import React from 'react';

const OverlayModel = (props) => {
  return (
    <div
      className='modal fade'
      id={props.overlayId}
      tabIndex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div
        className='modal-dialog  modal-dialog-centered'
        style={{ maxWidth: '1250px' }}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title fs-4 fw-bold'>{props.title}</h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-12 col-sm-6 modal-body--left'>
                <img src={props.imgSrc} />
              </div>
              <div className='col-12 col-sm-6'>
                <h4 className='title'>Project Info</h4>
                <p>{props.modelInfo}</p>
                <div className='model-details'>
                  <h4 className='title'>Project Details:</h4>
                  <ul>
                    <li>Client: {props.detailsName}</li>
                    <li>
                      Technologies:
                      <div className='tags'>
                        {props.tags.map((tag, index) => (
                          <span
                            className='badge rounded-pill bg-primary'
                            key={index}
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                    </li>
                    <li>Date: {props.detailsDate}</li>
                    <li>
                      URL:
                      <a
                        className='url'
                        target='_blank'
                        href={`http://${props.detailsUrl}`}
                      >
                        {props.detailsUrlName}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-primary'
              data-bs-dismiss='modal'
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlayModel;
