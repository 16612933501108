import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { CssBaseline, Box, Container, Stack, Divider } from '@mui/material';

// Components
import Cover from '../Cover/Cover';
import ProfilePic from '../ProfilePic/ProfilePic';
import BottomNavigation from '../BottomNavigation/BottomNavigation';
import Tabs from '../Tabs/Tabs';

// style
import './App.scss';

const App = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth='lg' sx={{ padding: 0 }}>
        <div>
          <Cover />
          <Stack direction='row' spacing={2}>
            <ProfilePic />
            <Box
              component='div'
              sx={{ width: '100%', display: { xs: 'none', sm: 'block' } }}
            >
              <Tabs />
            </Box>
          </Stack>
          <div id='detail' className='page-outlet'>
            <Outlet />
          </div>
          <Box
            sx={{
              width: '100%',
              display: { xs: 'block', sm: 'none' },
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 100,
              backgroundColor: '#0250a1',
            }}
          >
            <Divider sx={{ marginBottom: '3px' }} />
            <BottomNavigation />
          </Box>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default App;
