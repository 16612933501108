// compoentns
import Heading from '../../components/Heading/Heading';
import Timeline from '../../components/Timeline/Timeline';
import TimelineCard from '../../components/TimelineCard/TimelineCard';

// styles
import './Education.scss';
// images
import Seneca from '../../assets/certificates/seneca.png';
import Esoft from '../../assets/certificates/esoft.png';
import FrontendCoursera from '../../assets/certificates/frontend-coursera.png';
import FullstackLinkedin from '../../assets/certificates/fullstack-linkedin.png';
import htmlBitdigree from '../../assets/certificates/html-bitDigree.png';
import jsSololearn from '../../assets/certificates/js-sololearn.png';
import cssSololearn from '../../assets/certificates/css-sololearn.png';
import htmlSololearn from '../../assets/certificates/html-sololearn.png';

const Education = () => {
  return (
    <div className='education'>
      <div className='diploma-section'>
        <Heading bgTitle='Education' ftTitle='Diploma' />
        <div className='row'>
          <div className='col-md-7 '>
            <Timeline
              year='2021'
              company='Seneca College'
              title='Computer Programmer'
              description='Through a variety of practical and theoretical courses, got skills in mobile development, web development and distributed programming techniques and database administration'
            />
            <Timeline
              company='Esoft Metro Campus'
              title='Information Technology in Diploma'
              description='knowledge of the fundamentals of IT, which would function as a foundation'
            />
          </div>
        </div>
      </div>
      <div className='certificate-section'>
        <Heading bgTitle='Education' ftTitle='Certificates' />
        <div className='certificates'>
          <TimelineCard
            bgColor='#fff4f4'
            company='Seneca College'
            title='Computer Programmer'
            imgSrc={Seneca}
          />
          <TimelineCard
            bgColor='#fff4f4'
            company='Esoft Metro Campus'
            title='Information Technology'
            imgSrc={Esoft}
          />
          <TimelineCard
            bgColor='#fff4f4'
            company='Coursera'
            title='Front-end Web Developer'
            imgSrc={FrontendCoursera}
          />
          <TimelineCard
            bgColor='#eef5fa'
            company='LinkedIn'
            title='Full Stack Developer'
            imgSrc={FullstackLinkedin}
          />
          <TimelineCard
            bgColor='#fff1fb'
            company='BitDigree'
            title='Advanced HTML5 Specialist'
            imgSrc={htmlBitdigree}
          />
          <TimelineCard
            bgColor='#f2f4ff'
            company='Sololearn'
            title='JavaScript Complete Course'
            imgSrc={jsSololearn}
          />
          <TimelineCard
            bgColor='#fff1fb'
            company='Sololearn'
            title='CSS Complete Course'
            imgSrc={cssSololearn}
          />
          <TimelineCard
            bgColor='#eef5fa'
            company='Sololearn'
            title='HTML Fundamentals'
            imgSrc={htmlSololearn}
          />
        </div>
      </div>
    </div>
  );
};

export default Education;
