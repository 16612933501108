// styles
import './Timeline.scss';

const Timeline = (props) => {
  return (
    <div className='timeline clearfix'>
      <div className='timeline-item '>
        <div className='left-part'>
          <h5 className='item-period'>{props.year}</h5>
          <span className='item-company'>{props.company}</span>
        </div>
        <div className='divider'></div>
        <div className='right-part'>
          <h4 className='item-title'>{props.title}</h4>
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
