import './WideCard.scss';

const WideCard = (props) => {
  return (
    <div className='wideCard'>
      <h3 className='title'>{props.title}</h3>
      <p className='company text-danger'>{props.company}</p>
      <p className='duration badge bg-primary text-2 fw-400'>
        {props.duration}
      </p>
      <div className='description'>
        <ul>{props.children}</ul>
      </div>
    </div>
  );
};

export default WideCard;
