import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { BottomNavigationAction } from '@mui/material';
// MUI icons
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

// styles

const BottomNavigation = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <NavLink
        to={'/'}
        style={{
          textDecoration: 'none',
          color: 'inherit',
          paddingBottom: 0,
        }}
      >
        <BottomNavigationAction
          label='About'
          icon={<PersonIcon sx={{ color: '#fff', fontSize: 25 }} />}
        />
      </NavLink>
      <NavLink
        to={'education'}
        style={{ textDecoration: 'none', color: 'inherit', paddingBottom: 0 }}
      >
        <BottomNavigationAction
          label='Education'
          icon={<SchoolIcon sx={{ color: '#fff', fontSize: 25 }} />}
        />
      </NavLink>
      <NavLink
        to={'work'}
        style={{ textDecoration: 'none', color: 'inherit', paddingBottom: 0 }}
      >
        <BottomNavigationAction
          label='Experience'
          icon={<BusinessCenterIcon sx={{ color: '#fff', fontSize: 25 }} />}
        />
      </NavLink>
    </div>
  );
};

export default BottomNavigation;
